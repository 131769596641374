import { css } from "styled-components";

const size = {
  small: 480,
  medium: 960,
  large: 1140,
};

type SizeKeys = keyof typeof size;

export const above = (Object.keys(size) as Array<keyof typeof size>).reduce(
  (acc, label) => {
    acc[label] = (...args: TemplateStringsArray) => css`
      @media (min-width: ${size[label as SizeKeys]}px) {
        ${css(args)}
      }
    `;
    return acc;
  },
  {} as { [index: string]: Function }
);

export const below = (Object.keys(size) as Array<keyof typeof size>).reduce(
  (acc, label) => {
    acc[label] = (...args: TemplateStringsArray) => css`
      @media (max-width: ${size[label as SizeKeys]}px) {
        ${css(args)}
      }
    `;
    return acc;
  },
  {} as { [index: string]: Function }
);
