import React from "react";
import styled from "styled-components";
import { NavLinks } from "./NavLinks";
import HeaderBackground from "../../../assets/Header_Blobs.inline.svg";

export const HeaderContainer = styled.nav`
  height: 100px;
  width: 100%;
  z-index: 100;
  position: relative;
`;

const NavLinkWrapper = styled.div`
  position: relative;
`;

export const Header = () => {
  return (
    <HeaderContainer>
      <HeaderBackground
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
        }}
      />
      <NavLinkWrapper>
        <NavLinks />
      </NavLinkWrapper>
    </HeaderContainer>
  );
};
