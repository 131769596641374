/**
 * Takes a base color and returns it with the opacity adjusted.
 *
 * @param baseColor The color to adjust.  Should be in hex format including the #.
 * @param opacity The opactity to set the color to.  "00"-"FF"
 * @returns The base color with the specified opacity.
 */
export const withOpacity = (baseColor: string, opacity: string) => {
  return `${baseColor}${opacity}`;
};
