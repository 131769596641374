import { withOpacity } from "../utilities";
export const DarkTheme = {
  colors: {
    background: {
      primary: "#192c35",
      primary20: withOpacity("#192c35", "33"),
      primary40: withOpacity("#192c35", "66"),
      primary60: withOpacity("#192c35", "99"),
      primary80: withOpacity("#192c35", "CC"),
      secondary: "#020203",
      secondary20: withOpacity("#020203", "33"),
      secondary40: withOpacity("#020203", "66"),
      secondary60: withOpacity("#020203", "99"),
      secondary80: withOpacity("#020203", "CC"),
      focus: "#bbcc00",
    },
    site: {
      primary: "#569087",
      primary20: withOpacity("#569087", "33"),
      primary40: withOpacity("#569087", "66"),
      primary60: withOpacity("#569087", "99"),
      primary80: withOpacity("#569087", "CC"),
      secondary: "#25253F",
      secondary20: withOpacity("#25253F", "33"),
      secondary40: withOpacity("#25253F", "66"),
      secondary60: withOpacity("#25253F", "99"),
      secondary80: withOpacity("#25253F", "CC"),
    },
    text: {
      body: "#C3CBD1",
      body20: withOpacity("#C3CBD1", "33"),
      body40: withOpacity("#C3CBD1", "66"),
      body60: withOpacity("#C3CBD1", "99"),
      body80: withOpacity("#C3CBD1", "CC"),
      body90: withOpacity("#C3CBD1", "E6"),
      heading: "#569087",
      heading20: withOpacity("#569087", "33"),
      heading40: withOpacity("#569087", "66"),
      heading60: withOpacity("#569087", "99"),
      heading80: withOpacity("#569087", "CC"),
      headingDark: "#2F524C",
    },
    link: {
      default: "#45CAD5",
      active: "#85D0D6",
    },
    activities: {
      guitar: "#389355",
      running: "#60A9BC",
      family: "#BCA68A",
      coding: "#954545",
      hiking: "#40748C",
    },
  },
};

type ThemeType = typeof DarkTheme;
export type { ThemeType };
