import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { DarkTheme } from "../css/AppThemes";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
import { below } from "../utilities";
import { GlobalStyle } from "../css/Global";
import { Header } from "../components/Header";

const Background = styled.div`
  background-color: ${(props) => props.theme.colors.background.primary};
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  padding: 0 96px 44px 96px;
  min-height: 100vh;

  ${below.medium`
    padding: 0 36px 44px 36px;
  `};
`;

const query = graphql`
  query SiteMetadata {
    site {
      siteMetadata {
        siteUrl
        title
        description
      }
    }
  }
`;

export const Layout = ({ children }: { children?: React.ReactNode }) => {
  const data = useStaticQuery(query);
  return (
    <ThemeProvider theme={DarkTheme}>
      <GlobalStyle />
      <Background>
        <Helmet>
          <title>{data.site.siteMetadata.title}</title>
          <meta
            name="description"
            content={data.site.siteMetadata.description}
          />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, viewport-fit=cover"
          />
        </Helmet>
        <Header />
        <ContentWrapper>{children}</ContentWrapper>
      </Background>
    </ThemeProvider>
  );
};

export default Layout;
