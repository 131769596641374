import React from "react";
import { Link } from "gatsby";
import { below } from "../../utilities";
import styled from "styled-components";

export const NavLinksContainer = styled.nav`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 8px;
  .flexItem {
    margin-left: 8px;

    :first-child {
      margin-left: 0;
    }
  }
`;

const NavLinksWrapper = styled.div`
  width: 100%;
  height: 60px;
  padding: 0 64px;
  ${below.small`
    padding: 0 24px;
  `}
`;

const NavLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  transition: 0.1s all;
  padding: 8px;
  border-radius: 12px;
  color: ${(props) => props.theme.colors.link.default};
  background-color: ${(props) => props.theme.colors.background.primary40};
  :hover {
    color: ${(props) => props.theme.colors.link.active};
    box-shadow: 2px 2px 8px 1px
      ${(props) => props.theme.colors.background.primary};
  }
`;

// TODO: iPad and under should collapse these to a dropdown menu

export const NavLinks = () => {
  return (
    <NavLinksWrapper>
      <NavLinksContainer>
        <div>
          <NavLink to="/" className={"flexItem"}>
            Home
          </NavLink>
        </div>
        <div>
          <NavLink to="/resume" className={"flexItem"}>
            Resume
          </NavLink>
          <NavLink to="/projects" className={"flexItem"}>
            Projects
          </NavLink>
          <NavLink to="/blog" className={"flexItem"}>
            Blog
          </NavLink>
          <NavLink to="/about" className={"flexItem"}>
            About
          </NavLink>

          {/*<NavLink to="/" className={"flexItem"}>
            Hobbies
          </NavLink>
          <NavLink to="/" className={"flexItem"}>
            Projects
          </NavLink> */}
        </div>
      </NavLinksContainer>
    </NavLinksWrapper>
  );
};
