export const scrollToTop = (elementId?: string) => {
  const element = document.querySelector(`#${elementId}`);
  if (!element) {
    console.log("Element not found, scrolling to the top of the page");
  }
  const topPos = element
    ? element.getBoundingClientRect().top + window.pageYOffset
    : 0;
  window.scrollTo({
    top: topPos,
    behavior: "smooth",
  });
};
